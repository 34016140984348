<template>
  <el-container style="height: 960px; border: 1px solid #eee">
    <el-header style="text-align: right; font-size: 12px">
      <el-col :md="2">
        <ul class="el-menu--horizontal el-menu">
          <li class="el-menu-item">
            <a href="/my" style="text-decoration-line: none">
              <img src="@/assets/img/icon/logo.png" class="el-avatar--circle el-avatar--medium" alt="img">
              My
            </a>
          </li>
        </ul>
      </el-col>
      <el-dropdown>
        <img
          :src="user.avatarUrl"
          class="el-avatar--circle el-avatar--medium"
          style="margin-right: 10px; margin-top: 15px"
          alt=""
        >
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            icon="el-icon-s-platform"
            class="size"
            @click.native="goToHome"
          >主站</el-dropdown-item>
          <el-dropdown-item
            icon="el-icon-error"
            class="size"
            @click.native="goToLogout"
          >退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-container>
      <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
        <el-menu
          :default-active="this.$route.path"
          router
          class="el-menu-vertical-demo"
          :unique-opened="true"
        >
          <el-menu-item index="/my/record">
            <i class="el-icon-message" />
            <span slot="title">登录历史</span>
          </el-menu-item>
          <el-menu-item index="/my/message">
            <i class="el-icon-message" />
            <span slot="title">我的消息</span>
          </el-menu-item>
          <el-menu-item index="/my/vip">
            <i class="el-icon-film" />
            <span slot="title">我的会员</span>
          </el-menu-item>
          <el-menu-item index="/my/wallet">
            <i class="el-icon-wallet" />
            <span slot="title">我的钱包</span>
          </el-menu-item>
          <el-menu-item index="/my/oauth">
            <i class="el-icon-film" />
            <span slot="title">OAuth</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { userMixin } from 'assets/js/mixin'
import { getAuthedUser } from '@/utils/auth'

export default {
  name: 'My',
  mixins: [userMixin],
  data() {
    return {
      user: null,
      loginUser: null
    }
  },
  created() {
    document.title = '我的帐号'
    const userInfo = getAuthedUser()
    if (userInfo !== null) {
      this.user = userInfo
      this.loginUser = userInfo
    }
  }
}
</script>

<style>
.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>
